import {
  ErrorScreen,
  Login,
  ModalProvider,
  NextLoadingTransition,
  PersistLogin,
  RequireAuth,
  TopBar,
  Unauthorized,
} from '@assemblio/frontend/components';
import {
  AssemblyPlans,
  Explorer as ExplorerV2,
  FavoriteProducts,
  OperationalResource,
  OperationalResources,
  Product,
  Project,
  RecentProducts,
} from '@assemblio/frontend/explorer';
import { usePreventZoom } from '@assemblio/frontend/hooks';
import { UIController, useWebSocket } from '@assemblio/frontend/stores';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { enableMapSet, enablePatches } from 'immer';
import { Suspense } from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ThemeVariablesResolver } from '../theme/CSSVariables';
import { composerTheme } from '../theme/Composer.theme';
import classes from './Composer.module.scss';
import { Disassembler as DisassemblerV2 } from './pages/Disassembler';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
enableMapSet();
enablePatches();

const Composer = () => {
  usePreventZoom();
  useWebSocket();
  UIController.setQueryClient(queryClient);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <SentryRoutes>
      <Route path="/login" element={<Login />} />
      <Route path="/unauthorized" element={<Unauthorized />} />

      <Route element={<PersistLogin />}>
        <Route element={<RequireAuth allowedRoles={['editor', 'manager']} />}>
          <Route
            element={
              <ModalProvider>
                <div id="container" className={classes.container}>
                  <Outlet />
                </div>
              </ModalProvider>
            }
          >
            <Route path="/explorer" element={<ExplorerV2 />}>
              <Route index element={<AssemblyPlans />} />
              <Route path=":folderId" element={<AssemblyPlans />} />
              <Route path="project/:projectId" element={<Project />} />
              <Route path="product/:productId" element={<Product />}>
                <Route path=":tab" element={<Product />} />
              </Route>
              <Route path="favorites" element={<FavoriteProducts />} />
              <Route path="recent" element={<RecentProducts />} />
              <Route path="resources" element={<OperationalResources />} />
              <Route path="resources/:resourceId" element={<OperationalResource />} />
            </Route>
            <Route
              path="/disassembler/:instructionId"
              element={
                <div className={classes.disassembler_wrapper}>
                  <div className={classes.disassembler_header}>
                    <TopBar />
                  </div>
                  <div className={classes.disassembler_content}>
                    <Outlet />
                  </div>
                </div>
              }
            >
              <Route index element={<NextLoadingTransition component={<DisassemblerV2 />} />} />
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="*" element={<Navigate replace to="/explorer" />} />
    </SentryRoutes>
  );
};

export const ComposerWithProviders = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider cssVariablesResolver={ThemeVariablesResolver} defaultColorScheme={'dark'} theme={composerTheme}>
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
          <Notifications containerWidth={'100%'} position={'bottom-center'} zIndex={1000} />
          <Suspense fallback={<h2>Loading...</h2>}>
            <ReactQueryDevtools initialIsOpen={false} />
            <BrowserRouter>
              <Composer />
            </BrowserRouter>
          </Suspense>
        </Sentry.ErrorBoundary>
      </MantineProvider>
    </QueryClientProvider>
  );
};
